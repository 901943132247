<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="card bg-white">
        <div class="grid">
          <div class="col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12 md:col-3">
                <label>Tanggal Bukti</label>
                <InputText
                  v-model="form.pi_at"
                  class="w-full"
                  input-class="w-full"
                  dateFormat="dd/mm/yy"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-3">
                <label>Nomor Bukti</label>
                <InputText
                  v-model="form.pi_no"
                  type="text"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-3">
                <label>Tanggal Faktur</label>
                <InputText
                  v-model="form.pi_inv_at"
                  class="w-full"
                  input-class="w-full"
                  dateFormat="dd/mm/yy"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-3">
                <label>Nomor Faktur</label>
                <InputText
                  v-model="form.pi_inv"
                  type="text"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
            </div>
          </div>

          <div class="col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12 md:col-6">
                <label>Supplier</label>
                <InputText
                  v-model="form.supplier.nama"
                  type="text"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
              <div class="field col-12 md:col-3">
                <label> No Seri Faktur Pajak </label>
                <InputText
                  v-model="form.pajak_no"
                  type="text"
                  class="w-full"
                  :class="{
                    'p-invalid': v$.form.pajak_no.$invalid && submitted,
                  }"
                  input-class="w-full"
                />
                <small
                  v-if="
                    (v$.form.pajak_no.$invalid && submitted) ||
                    v$.form.pajak_no.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.pajak_no.required.$message }}</small
                >
              </div>
              <div class="field col-12 md:col-3">
                <label> No. Retur </label>
                <InputText
                  v-model="form.pr_no"
                  type="text"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
            </div>
          </div>

          <div class="col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label> DPP </label>
                <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.dpp_pi" :min="0" class="w-full form-edit-faktur" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label> PPN </label>
                <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.ppn_pi" :min="0" class="w-full form-edit-faktur" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label> Masa Pajak </label>
                <Dropdown
                  v-model="form.masa_pajak"
                  :options="list_masa_pajak"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                />
              </div>
            </div>
          </div>

          <div class="col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label> DPP Retur </label>
                <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.dpp_pr" :min="0" class="w-full form-edit-faktur" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label> PPN Retur </label>
                <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.ppn_pr" :min="0" class="w-full form-edit-faktur" disabled />
              </div>
              <div class="field col-12 md:col-4">
                <label> Masa Retur </label>
                <Dropdown
                  v-model="form.masa_retur"
                  :options="list_masa_pajak"
                  optionValue="id"
                  optionLabel="nama"
                  class="w-full"
                />
              </div>
            </div>
          </div>

          <div class="col-12 md:col-12">
            <div class="formgrid grid">
              <div class="field col-12 md:col-4">
                <label> DPP Pajak </label>
                <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.dpp_pjk" :min="0" class="w-full form-edit-faktur"/>
              </div>
              <div class="field col-12 md:col-4">
                <label> PPN Pajak </label>
                <InputNumber inputId="locale-indonesian" locale="en-ID" v-model="form.ppn_pjk" :min="0" class="w-full form-edit-faktur"/>
              </div>
              <div class="field col-12 md:col-4">
                <label> Tahun Retur </label>
                <InputText v-model="form.tahun_retur"
                  :maxLength="4"
                  class="w-full"
                  input-class="w-full"
                  onfocus="this.select();"
                  :class="{
                    'p-invalid': v$.form.tahun_retur.$invalid && submitted,
                  }"
                />
                <small
                  v-if="
                    (v$.form.tahun_retur.$invalid && submitted) ||
                    v$.form.tahun_retur.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.tahun_retur.minLength.$message }}</small
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex pt-2 justify-content-end align-items-end">
          <Button
            :loading="loading"
            label="Simpan"
            icon="pi pi-check"
            type="submit"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { formatCurrency, formatDate } from '@/helpers'
import { helpers, minLength, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {},
  data() {
    return {
      submitted: false,
      form: this.item,
      list_masa_pajak: [
        { id: 0, nama: ' ' },
        { id: 1, nama: 'Januari' },
        { id: 2, nama: 'Februari' },
        { id: 3, nama: 'Maret' },
        { id: 4, nama: 'April' },
        { id: 5, nama: 'Mei' },
        { id: 6, nama: 'Juni' },
        { id: 7, nama: 'Juli' },
        { id: 8, nama: 'Agustus' },
        { id: 9, nama: 'September' },
        { id: 10, nama: 'Oktober' },
        { id: 11, nama: 'November' },
        { id: 12, nama: 'Desember' },
      ],
    }
  },
  created() {
    this.form.tahun_retur = this.item.tahun_retur == 0 ? "" : this.item.tahun_retur
  },
  computed: {
    totalDPP() {
      return Math.floor(this.form.dpp_pi - this.form.dpp_pr)
    },
    totalPPN() {
      return Math.floor(this.form.ppn_pi - this.form.ppn_pr)
    },
  },
  mounted() {},
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        pajak_no: {
          required: helpers.withMessage('No Seri Pajak harus diisi.', required),
        },
        tahun_retur: {
          minLength: helpers.withMessage('Tahun Retur minimal memiliki 4 digit', minLength(4)),
        }
      },
    }
  },
}
</script>

<style scoped>
.p-inputnumber-input {
  text-align: right;
}
</style>
