import http from '../clients/Axios'

export default class FakturPajakMasukanService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''
    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (el == 'pajak_masukan.masa_pajak' || el == 'pajak_masukan.masa_retur') {
          if (filters[el].value != null) {
            filters_param =
              filters_param + '&filter[' + el + ']=' + filters[el].value
          }
        } else {
          if (filters[el].value) {
            filters_param =
              filters_param + '&filter[' + el + ']=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(
      `/pajak/masukan/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )
    return res.data
  }

  async add(form) {
    return await http.post('/pajak/masukan', form)
  }

  async export() {
    const unixTime = Math.floor(Date.now() / 1000)
    return http({
      url: `/pajak/masukan/export?t=${unixTime}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
